<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>
          {{ $t('company_info')}}
        </span>
      </template>
      <companyTabList :sd="companydata.si" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="BoxIcon" />
        <span>
          {{ $t('technologies')}}
        </span>
      </template>
      <technologyTabList :sd="companydata.techno" />
    </b-tab>

    <!-- <b-tab>
      <template #title>
        <feather-icon icon="StarIcon" />
        <span>
          {{ $t('products')}}
        </span>
      </template>
      <productTabList :sd="companydata.products" />
    </b-tab> -->
  </b-tabs>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BImg,
    BListGroup,
    BListGroupItem,
  } from 'bootstrap-vue'
  import companyTabList from './companyTabList.vue'
  import technologyTabList from './technologyTabList.vue'
  import companydata from './companydata.js'
  import productTabList from './productTabList.vue'

  export default {
    components: {
      BCard,
      BImg,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      companyTabList,
      technologyTabList,
      productTabList,
    },

    data() {
      return {
        companydata,
      }
    },
  }
</script>
