<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <div class="d-flex justify-content-start">
      <div class="w-100">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Available</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.swmem_memory
                  ? system_monitor.system.swmem_memory.available.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Percentage</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.swmem_memory
                  ? system_monitor.system.swmem_memory.percentage.value[0]
                  : ''
              }}
              %
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Total</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.swmem_memory
                  ? system_monitor.system.swmem_memory.total.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Used</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.swmem_memory
                  ? system_monitor.system.swmem_memory.used.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from 'bootstrap-vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    components: {
      BCard,
      BAvatar,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
    },
    methods: {},
    data() {
      return {}
    },
    computed: {
      ...mapState('systemMonitor', ['system_monitor']),
    },
  }
</script>

<style></style>
