<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <app-collapse accordion type="default">
      <app-collapse-item
        class="border-top-0 border-bottom-light border-left-0 border-right-0"
        :title="item.device.value"
        v-for="(item, index) in system_monitor.system.disk"
        :key="index"
      >
        <div class="d-flex justify-content-start">
          <div class="w-100">
            <b-list-group
              flush
              v-for="(element, index) in item"
              :key="index"
              class="p-0"
            >
              <b-list-group-item
                style="padding: 0.3rem 0"
                class="d-flex justify-content-between align-items-center"
              >
                <small>
                  {{ element.title }}
                </small>
                <h5>
                  {{ element.value }}
                </h5>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
    BTable,
    BButton,
    BCollapse,
    BCardHeader,
    BCardBody,
  } from 'bootstrap-vue'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

  export default {
    directives: {
      // 'b-collapse': BCollapse,
      // v-b-toggle
      'b-toggle': BCollapse,
    },
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BAvatar,
      BCollapse,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
      BTable,
      BButton,

      AppCollapse,
      AppCollapseItem,
    },
    methods: {},
    data() {
      return {
        showCollapse: true,
      }
    },
    computed: {
      ...mapState('systemMonitor', ['system_monitor']),
    },
  }
</script>

<style>
  .w-10 {
    width: 12% !important;
  }
</style>
