<template>
  <ChartjsLineAreaChart :cpuData="cpuData" />
</template>

<script>
  import {} from 'bootstrap-vue'
  import ChartjsLineAreaChart from './ChartjsLineAreaChart.vue'

  export default {
    components: {
      ChartjsLineAreaChart,
    },

    props: ['cpuData'],
  }
</script>
