<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="ServerIcon" />
        <span>
          {{ $t('system_info')}}
        </span>
      </template>
      <systemInfo />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="CpuIcon" />
        <span>
          {{ $t('cpu_info')}}
        </span>
      </template>
      <cpuInfo />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="DatabaseIcon" />
        <span>
          {{ $t('database_info')}}
        </span>
      </template>
      <memoryInfo />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="DiscIcon" />
        <span>
          {{ $t('disk_info')}}
        </span>
      </template>
      <diskInfo />
    </b-tab>
  </b-tabs>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BImg,
    BListGroup,
    BListGroupItem,
  } from 'bootstrap-vue'
  import systemInfo from './systemInfo.vue'
  import cpuInfo from './cpuInfo.vue'
  import memoryInfo from './memoryInfo.vue'
  import diskInfo from './diskInfo.vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    components: {
      BCard,
      BImg,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      systemInfo,
      cpuInfo,
      memoryInfo,
      diskInfo,
    },

    data() {
      return {}
    },
    computed: {
      ...mapState('systemMonitor', ['system_monitor']),
    },
    async created() {},
  }
</script>