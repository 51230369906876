<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <div class="d-flex justify-content-start">
      <div class="mr-2" v-if="sd.platform">
        <b-avatar
          size="120"
          square
          variant="light-succes"
          :src="getImage(sd.platform)"
        />
      </div>
      <div class="w-100">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-for="(i, index) in sd.data"
            :key="index"
          >
            <small>{{ i.title }}</small>

            <h6 v-if="i.link" class="text-right w-80">
              <a :href="i.link" target="_blank">{{ i.value }}</a>
            </h6>

            <h6
              v-else-if="i.title === 'Электронная почта'"
              class="text-right w-80"
            >
              <a :href="'mailto:' + i.value" target="_blank">{{ i.value }}</a>
            </h6>

            <h6 v-else-if="i.title === 'Контакты'" class="text-right w-80">
              <a :href="i.link" target="_blank">{{ i.value }}</a>
            </h6>

            <h6 v-else class="text-right w-80">{{ i.value }}</h6>
          </b-list-group-item>
          <b-list-group-item
            v-if="sd.core"
            class="d-flex justify-content-between align-items-center"
          >
            <small>Cores [{{ sd.core.length }}]</small>
            <b-badge
              v-for="(i, index) in sd.core"
              :key="index"
              class="mr-1"
              variant="light-success"
              >{{ i.value }}</b-badge
            >
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BAvatar,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
    },
    props: {
      sd: {
        type: Object,
        required: false,
      },
    },
    methods: {
      getImage(arg) {
        return this.data.filter(item => item.platform == arg)[0].image
      },
    },
    //     mounted(){
    //   console.log(this.sidata)
    // },
    data() {
      return {
        data: [
          {
            platform: 'linux',
            image: require('@/views/Administrator/System/components/icons/ZK.png'),
          },
        ],
      }
    },
  }
</script>

<style>
  .w-80 {
    width: 70% !important;
  }
</style>
