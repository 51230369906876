<template>
  <b-card no-body class="shadow-none bg-transparente border-primary p-1">
    <!-- {{system_monitor[0].system.value}} -->
    <div class="d-flex justify-content-start">
      <!-- <div class="mr-2" v-if="system_monitor[0].system.value">
            <b-avatar
              size="120"
              square
              variant="light-succes"
              :src="getImage(system_monitor[0].system.value)"
            />
          </div> -->
      <div class="w-100">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>System</small>
            <h5>
              {{
                system_monitor &&
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.system_info
                  ? system_monitor.system.system_info.system.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Machine</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.system_info
                  ? system_monitor.system.system_info.machine.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Processor</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.system_info
                  ? system_monitor.system.system_info.processor.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Version</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.system_info
                  ? system_monitor.system.system_info.version.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Node Name</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.system_info
                  ? system_monitor.system.system_info.node_name.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <small>Release</small>
            <h5>
              {{
                system_monitor &&
                system_monitor.system &&
                system_monitor.system.system_info
                  ? system_monitor.system.system_info.release.value
                  : ''
              }}
            </h5>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
  import {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from 'bootstrap-vue'

  import { mapActions, mapState } from 'vuex'

  export default {
    components: {
      BCard,
      BAvatar,
      BCardText,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BBadge,
    },
    methods: {
      getImage(arg) {
        return this.platforms.filter(item => item.platform == arg)[0].image
      },
    },
    data() {
      return {
        platforms: [
          {
            platform: 'linux',
            image: require('@/views/Administrator/System/components/icons/linux.svg'),
          },
          {
            platform: 'windows',
            image: require('@/views/Administrator/System/components/icons/windows.svg'),
          },
          {
            platform: 'mac',
            image: require('@/views/Administrator/System/components/icons/mac.png'),
          },
        ],
      }
    },
    computed: {
      ...mapState('systemMonitor', ['system_monitor']),
    },
  }
</script>

<style></style>
